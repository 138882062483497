<template>
  <el-form
    class="login-form"
    :rules="rules"
    :model="model"
    ref="form"
    :validate-on-rule-change="false"
  >
    <el-form-item
      :style="`margin-bottom: ${displayErrorMessage ? 30 : 20}px`"
      prop="email"
      :error="errors.email"
    >
      <span slot="error" slot-scope="scope" class="el-form-item__error">
        <span v-if="displayErrorMessage">
          {{ $t("register.email_is_already_registered_part1") }}
          <router-link :to="{ name: 'Login', query: { email: model.email } }">
            {{ $t("register.email_is_already_registered_part2") }}
          </router-link>
          {{ $t("register.email_is_already_registered_part3") }}
          <router-link
            :to="{ name: 'ForgetPassword', query: { email: model.email } }"
          >
            {{ $t("register.email_is_already_registered_part4") }}
          </router-link>
          {{ $t("register.email_is_already_registered_part5") }}
        </span>
        <span v-else>
          {{ scope.error }}
        </span>
      </span>
      <el-input
        v-model="model.email"
        :placeholder="$t('login.email')"
        prefix-icon="el-icon-user"
        @blur="trimEmpty()"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="model.password"
        :placeholder="$t('login.password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="verifyPassword">
      <el-input
        v-model="model.verifyPassword"
        :placeholder="$t('login.verify_password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item v-if="model.referral_code !== ''" prop="referral_code">
      <el-input
        v-model="model.referral_code"
        prefix-icon="el-icon-paperclip"
        :disabled="true"
      ></el-input>
    </el-form-item>
    <el-form-item prop="captcha" :error="errors.captcha">
      <el-row>
        <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
          <el-image
            v-if="captchaImg"
            @click="getCaptcha"
            :src="captchaImg"
            fit="fill"
          ></el-image>
        </el-col>
        <el-col :xs="{ span: 24 }" :sm="{ span: 15, offset: 1 }">
          <el-input
            v-model="model.captcha"
            :placeholder="$t('login.captcha')"
          ></el-input>
        </el-col>
      </el-row>
    </el-form-item>
    <p class="terms-text">
      By registering, you agree to our
      <router-link
        :to="{
          name: 'Terms'
        }"
        target="_blank"
        class="terms-link"
        >terms and conditions</router-link
      >.
    </p>
    <el-form-item align="right">
      <el-button type="primary" style="width: 100%;" block @click="handleRegister">{{
        $t("login.register")
      }}</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import formMixin from "@/mixins/form";
import User from "@/apis/user";

export default {
  mixins: [formMixin],
  data: () => {
    return {
      displayErrorMessage: false,
      model: {
        email: "",
        password: "",
        verifyPassword: "",
        captcha: "",
        referral_code: ""
      },
      captchaImg: null,
      captchaKey: null,
      captchaIsInvalid: false,
      errors: {
        email: "",
        captcha: ""
      }
    };
  },
  computed: {
    rules() {
      const checkVerifyPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      return {
        email: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        verifyPassword: [{ validator: checkVerifyPassword, trigger: "blur" }],
        captcha: [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ]
      };
    }
  },

  mounted() {
    if (this.$route.query.referral_code) {
      this.model.referral_code = this.$route.query.referral_code;
    }
    this.getCaptcha();
  },

  methods: {
    ...mapActions("user", ["register"]),
    async getCaptcha() {
      const { img, key } = await User.getCaptcha();
      this.captchaImg = img;
      this.captchaKey = key;
    },
    async handleRegister() {
      this.errors = {};
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const { email, password, captcha, referral_code } = this.model;
      const registerReq = { email, password, captcha, referral_code };
      registerReq["captchaKey"] = this.captchaKey;

      try {
        await this.register(registerReq);
      } catch ({ data: { error, errors } }) {
        const EMAIL_HAS_TAKEN = "The email has already been taken.";
        const CAPTCHA_IS_INVALID = "validation.captcha_api";
        console.log(error);
        if (error == EMAIL_HAS_TAKEN) {
          this.errors = {
            ...this.errors,
            email: EMAIL_HAS_TAKEN
          };
          this.displayErrorMessage = true;
          return;
        }
        Object.keys(errors).forEach(error => {
          switch (errors[error][0]) {
            case EMAIL_HAS_TAKEN:
              this.errors = {
                ...this.errors,
                email: EMAIL_HAS_TAKEN
              };
              this.displayErrorMessage = true;
              break;
            case CAPTCHA_IS_INVALID:
              this.errors = {
                ...this.errors,
                captcha: this.$t("register.the_captcha_code_is_invalid")
              };
              break;
            default:
          }
        });
        return errors;
      }

      await this.$message({
        message: this.$t("message.register_success"),
        type: "success"
      });

      this.$router.push({
        name: "RegisterDetail"
      });
    },
    trimEmpty() {
      this.model.email = this.model.email.trim();
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.errors = {
          email: "",
          captcha: ""
        };
      }
    }
  }
};
</script>
<style scoped>
.register-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: #ff770c;
}

.clearfix {
  color: white;
}

.el-button {
  outline: none;
}
::v-deep .el-form-item__content {
  line-height: 2;
}
.terms-text {
  font-size: 14px;
  color: #606266;
  text-align: center;
  margin:0 0 22px 0;
  line-height: 1.5;
  padding: 0 10px;
}
.terms-link {
  color: #409EFF;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}
.terms-link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>
